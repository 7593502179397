@import "colors.scss";
@import "mixins.scss";

check-in-calendar {
  .check-in-calendar {
    background: $calendar-background;
    color: $calendar-color;

    full-calendar {


      thead {

        /* Hiding scrollbar for Chrome, Safari and Opera */
        .fc-scroller::-webkit-scrollbar {
          display: none;
        }

        /* Hiding scrollbar for IE, Edge and Firefox */
        .fc-scroller {
          scrollbar-width: none;
          /* Firefox */
          -ms-overflow-style: none;
          /* IE and Edge */
        }
      }

      .fc-timeline-now-indicator-arrow {
        border-color: rgba(0, 0, 0, .8);
        border-left-color: transparent;
        border-right-color: transparent;
      }

      .fc-timeline-now-indicator-line {
        border-color: rgba(0, 0, 0, .8);
        z-index: 999;
      }

      .fc-timeline-now-indicator-container {
        z-index: 999;
      }

      .fc-datagrid-cell-cushion {
        padding: 0px;
      }

      .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
        padding-bottom: 0px;
      }

      .fc-toolbar.fc-header-toolbar {
        margin-bottom: 10px;
        display: none;

        .fc-left {
          .view-icon {
            margin-top: 10px;
            margin-left: 5px;

            &.selected {
              color: $calendar-selected-color;
            }
          }
        }

        .fc-center {
          >div {
            display: inline-block;
          }

          .fc-prev-button,
          .fc-next-button {
            color: $calendar-color;
            background: transparent;
            border: 0px;
            outline: 0px;

            &:hover {
              background: transparent;
              border: 0px;
              outline: 0px;
            }

            .fc-icon {
              font-weight: bold;
            }
          }

          h2 {
            display: inline-block;
            transform: translateY(2px);
            font-size: 16px;
            text-transform: capitalize;
          }

          .fc-today-button {
            color: $calendar-color;
            background: transparent;
            border: 1px solid $calendar-color;
            font-size: 14px;
          }

          .date-selector,
          .search-button {
            transform: translateX(-10px) translateY(10px);
            font-size: 30px;
          }

          .search-button {
            transform: translateX(-25px) translateY(10px);
          }

          .quick-booking-button {
            transform: translateX(-42px) translateY(10px);
            font-size: 30px;
            font-weight: bold;
          }
        }

        .fc-right {
          position: relative;
          height: 50px;

          .daily-notes {
            border: 0px;
            outline: 0px;
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            width: 300px;
            background: white;
            transition: 0.3s ease-in;
            font-size: 10px;
            padding: 5px;
            z-index: 999999;

            &:focus {
              box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3);
              bottom: -50px;
              width: 500px;
              border-bottom-left-radius: 30px;
              font-size: 16px;
              padding: 10px;
            }
          }
        }
      }

      .fc-datagrid-header {
        .expand-toggle {
          opacity: 0.4;
          transform: translateY(0.2em);
          margin-right: 5px;
          margin-left: 5px;
        }
      }

      .fc-view {
        background: white;

        .fc-resource {
          height: 100%;
          padding: 0px;
          padding-right: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          position: relative;

          .resource {
            height: 100%;


            .expand-toggle {
              opacity: 0.4;
              transform: translateY(0.2em);
            }

            .name {
              vertical-align: top;
              padding-left: 5px;
            }

            .color {
              display: inline-block;
              width: 7px;
              margin-right: 0px;
              height: 25px;
              margin-top: 2px;
              float: right;
            }

            .persons {
              opacity: 0.4;
              position: absolute;
              top: 5px;
              right: 10px;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }


        .fc-scrollgrid-sync-table {
          tr {
            position: relative;
          }

          @for $i from 1 through 50 {
            tr:nth-child(#{$i}) {
              z-index: 99-$i;
            }
          }
        }

        //     .fc-time-area {
        .fc-event {
          background-color: $calendar-event-background-color;
          border: 0px;
          transform-origin: top;
          transition: 0.5s 0.3s ease-out;
          cursor: pointer;

          &.resource-availability {
            cursor: default;
            margin-left: -1px;
            margin-right: -1px;


            .resource-availability-inner {
              color: #00000099;
              text-align: center;
              font-size: 0.7em;
              margin-left: -1px;
              margin-right: -1px;

              &.available {
                background: $calendar-resource-available-color;
              }

              &.almost-full {
                background: $calendar-resource-almost-full-color;
              }

              &.has-bookings{
                background: $calendar-resource-has-bookings-color;
              }

              &.full {
                background: $calendar-resource-full-color;
              }
            }

          }

          &.resource-booking {
            background-color: $calendar-resource-booking-event-background-color;

            .activity {
              @include resource-booking-background();

              &.blocked {
                @include blocked-resource-booking-background();
              }
            }
          }

          &:not(.resource-availability):hover {
            color: black;
            min-width: 250px;
            transform: scale(1.02);
            z-index: 999999;
            overflow: unset;
            box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);

            &.has-following {
              transform: scale(1.02) translateY(40px);
            }
          }

          &.compact {
            max-height: 30px;
          }

          &.compact:hover {
            max-height: 200px;
          }

          .activity {
            padding-left: 20px;
            padding-top: 5px;
            position: relative;
            width: 100%;
            height: 100%;
            min-height: 50px;
            transition: 0.5s ease-out;
            background-color: $calendar-event-background-color;
            color: black;
            overflow: hidden;

            &.has-following:hover {
              margin-top: -40px;
            }


            &:not(.compact) {
              .compact-indicators {
                display: none;
              }
            }

            &.compact {
              transition: 0.5s 0.3s ease-out;

              .compact-indicators {
                line-height: 1em;
                margin-right: 4px;
                display: inline-block;
                width: 20px;
                transition: 0.5s 0.3s ease-out;
                transform: translateY(2px);
                overflow: hidden;

                .indicator-dot {
                  font-size: 2em;
                  vertical-align: middle;
                  font-weight: bold;
                }
              }

              .title {
                margin-top: 0px;
                font-weight: normal;
              }

              .ribbon {
                transition: 0.5s 0.3s ease-out;
              }

              &:not(:hover) {
                min-height: 0px;
                max-height: 30px;


                .time,
                hr,
                booking-icons {
                  transition: 0.5s 0.3s ease-out;
                  max-height: 0px;
                  opacity: 0;
                  transform: translateY(20px);
                }

                .following-arrow {
                  transition: 0.5s 0.3s ease-out;
                  opacity: 0;
                }

                .ribbon {
                  height: 20px;
                }
              }

              &:hover {
                max-height: 200px;

                .compact-indicators {
                  width: 0px;
                }

                .time,
                hr,
                booking-icons {
                  transition: 0.5s 0.3s ease-out;
                  opacity: 1;
                  max-height: 200px;
                  transform: translateY(0px);
                }

                .time {
                  opacity: .6;
                }

                hr {
                  opacity: .1;
                }

                .following-arrow {
                  transition: 0.5s 0.3s ease-out;
                  opacity: 1;
                }
              }
            }

            &.vip {
              background-color: #ffdf00 !important;
              overflow: visible;
              animation-iteration-count: infinite;
              animation-name: bounce;
              animation-duration: 1.9s;
              z-index: 999999;

              &::before {
                font-family: "Material Icons Outlined";
                content: "star";
                color: #ffd700 !important;
                border: 4 px solid black;
                border-radius: 20px;
                font-size: 50px;
                position: absolute;
                z-index: 99;
                top: -35px;
                left: -20px;
                text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
                animation-iteration-count: infinite;
                animation-name: tada;
                animation-duration: 0.9s;
              }

              &::after {
                content: "";
                position: absolute;
                z-index: 9;
                height: 100%;
                display: none;
                width: 100%;
                background: rgb(2, 0, 36);
                background: linear-gradient(153deg,
                    rgba(2, 0, 36, 0) 0%,
                    rgba(255, 255, 255, 1) 78%,
                    rgba(0, 212, 255, 0) 100%);
              }
            }

            @keyframes bounce {
              0% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              14% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              28% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              42% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              70% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }

            @keyframes tada {
              0% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }

              10%,
              20% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
                transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
              }

              30%,
              50%,
              70%,
              90% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
              }

              40%,
              60%,
              80% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
              }

              to {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }

            .ribbon {
              width: 6px;
              height: 50px;
              position: absolute;
              left: 5px;
            }

            .title {
              font-weight: bold;
              margin-top: 3px;
              opacity: 0.8;
              white-space: nowrap;
            }

            hr {
              opacity: 0.1;
            }

            .time {
              font-size: 10px;
              font-weight: bold;
              opacity: 0.6;
              white-space: nowrap;
            }

            /*
              .persons {
                position: absolute;
                top: 5px;
                right: 5px;
                opacity: 0.3;

                .number {
                  font-size: 16px;
                  text-align: right;
                  display: inline-block;
                  font-weight: bold;
                  margin-right: 2px;
                }

                .icon {
                  //  position: absolute;
                  // transform: translateX(-50%);
                  font-size: 18px;
                }
              }
              */

            .icons {
              font-size: 20px;
              display: inline;
              white-space: nowrap;

              .persons {
                font-size: 18px;
                background: $primary-color;
                color: white;
                padding: 1px;
                border-radius: 100%;
                font-size: 10px;
                display: inline-block;
                min-width: 15px;
                text-align: center;
                height: 15px;
                transform: translateX(-10px) translateY(-12px);
              }

              i {
                margin-left: 2px;
                margin-right: 2px;
              }

              .mat-icon {
                font-size: 20px;
                margin-right: -3px;
                margin-left: 4px;
                height: 20px;
              }

              .fa {
                font-size: 20px;
              }

              .svg-icon {
                margin-left: 6px;
                margin-right: 4px;
                transform: translateY(2px);
                width: 20px;
                height: 20px;

                &.neutral {}

                &.red {}

                &.green {}
              }
            }

            .following-arrow {
              position: absolute;
              top: 66%;
              left: 5px;
              font-size: 1.5em;
              z-index: 99;
              opacity: 1;
              height: auto;
              transition: 0.3s;
              display: none;
            }

            &.has-following .following-arrow {
              display: block;
            }

            .following-events {
              padding-left: 0px;
              transition: 0.3s 0.3s;

              .following-event {
                transition: 0.3s;
                height: 0px;
                overflow: hidden;
              }
            }

            &.has-following:hover {
              .following-arrow {
                bottom: auto;
                top: 56%;
                transform: rotate(-90deg);
                left: 25px;
                font-size: 3em;
              }

              .following-events {
                padding-left: 45px;

                .following-event {
                  height: 50px;
                  overflow: hidden;
                }
              }
            }
          }
        }

        tr:first-child .fc-event .activity.has-following:hover {
          margin-top: 0px;
        }

        tr:last-child .fc-event.has-following:hover {
          transform: scale(1.02);
        }

        .fc-rows td>div:hover {
          z-index: 9999;
        }

        //     }

        .fc-list-view {
          background: $calendar-background;

          .fc-list-table {
            table-layout: auto;

            tr {
              td {
                border: 0px;
                padding-top: 5px;
                padding-bottom: 5px;
                transition: 0.2s linear;
              }
            }
          }

          .fc-list-item {
            padding-bottom: 10px;
            transition: 0.2s linear;
            opacity: 1;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
              color: black;
              padding-right: 20px;
              min-width: 250px;
              z-index: 999999;
              overflow: unset;
            }

            table {
              width: auto;

              tr {
                td {
                  padding: 0px;
                }
              }
            }

            .ribbon {
              width: 5px;
              height: 85%;
              left: 3px;
            }

            .time {
              font-size: 10px;
              opacity: 0.6;
              white-space: nowrap;
            }

            .title {
              opacity: 0.6;
              font-size: 12px;
              font-weight: bold;
              padding-left: 10px;
              white-space: nowrap;
            }

            .persons {
              opacity: 0.6;
              font-size: 14px;
            }

            .price {
              text-align: right;
              padding-right: 10px;
              font-size: 14px;
              font-weight: bold;
            }

            .remark {
              font-size: 10px;
            }

            .icons {
              text-align: right;
              padding-right: 10px;
              font-size: 20px;
              direction: rtl;

              i {
                margin-left: 2px;
                margin-right: 2px;
              }

              .mat-icon {
                font-size: 18px;
              }
            }
          }
        }
      }

      .fc-timeline {
        .fc-col-resizer {
          cursor: default;
        }
      }
    }
  }
}

.mat-calendar {}
