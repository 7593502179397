@mixin resource-booking-background { 
    background-color: $calendar-resource-booking-event-background-color;
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.02) 25%,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 0.02) 50%,
      rgba(0, 0, 0, 0.02) 75%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 56.57px 56.57px;
}


@mixin blocked-resource-booking-background { 
  background-color: $calendar-blocked-resource-booking-event-background-color;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.02) 25%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.02) 50%,
    rgba(0, 0, 0, 0.02) 75%,
    rgba(255, 255, 255, 0) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 56.57px 56.57px;
}
