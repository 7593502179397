@import "colors";

.loader-wrapper
{
  text-align:center;
}
.loader-icon {
    display: inline-block;
    width: 64px;
    height: 64px;

    &.padded
    {
        margin: 15px;
    }

    &.large
    {
        width:150px;
        height:150px;
        &.padded
        {
            margin: 50px;
        }
    }
}
.loader-icon:after {
content: " ";
display: block;
width: 100%;
height: 100%;
margin: 1px;
border-radius: 50%;
border: 5px solid  $primary-color;
border-color: $primary-color transparent $primary-color transparent;
animation: loader-icon 1.2s linear infinite;
}
@keyframes loader-icon {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}