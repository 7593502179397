/* You can add global styles to this file, and also import other style files */
@import "node_modules/bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";
//@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "scss/indigo-pink";
//@import url("https://fonts.googleapis.com/css?family=Reem+Kufi");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
//@import "node_modules/@fullcalendar/core/main.css";
//@import "node_modules/@fullcalendar/timeline/main.css";
//@import "node_modules/@fullcalendar/resource-timeline/main.css";
@import "scss/colors";
@import "scss/loader-icon";
@import "scss/check-in-calendar";
@import "../../../src/scss/styleguide.scss";

//$fa-font-path: "node_modules/font-awesome/fonts";
@import "node_modules/font-awesome/scss/font-awesome.scss";

body,
.mat-mdc-table,
.mat-mdc-form-field,
.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  font-family: "Open Sans", sans-serif;
}

.mat-mdc-option {
  .mdc-list-item__primary-text {
    white-space: nowrap !important;
  }
}

.mat-mdc-form-field {
  margin-top: 10px;
}

.friendly-code-title {
  display: inline-block;
  padding-right: 10px;
  padding-left: 15px;
}

.friendly-code {
  display: inline-block;
}


.underline{
  text-decoration: underline;
}

/*
input[type="number"][matInput] {
  outline: 0px;
  border: 0px;
  border-bottom: 1px solid black;
  background: whitesmoke;
  //padding: 8px;
}
*/

.mat-mdc-dialog-surface {
  overflow-x: hidden;
}

mat-form-field.mat-form-field-disabled label {
  opacity: 0.6;
}

.mat-mdc-select-panel {
  min-width: fit-content !important;
}

body {
  background: $background-color;
  color: $primary-color;
  overflow-x: hidden;

  input[type="number"][matInput]:not(.mat-mdc-form-field input[type="number"][matInput]) {
    outline: 0px;
    border: 0px;
    border-bottom: 1px solid black;
    background: whitesmoke;
    padding: 8px;
  }

  label {
    margin-bottom: initial;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;

    &.break {
      line-break: anywhere;
    }
  }

  .no-border {
    border-width: 0px !important;
  }

  button:focus {
    outline: initial;
  }

  .print-show {
    visibility: collapse !important;
    height: 0px;
  }

  table.inverted {
    tr {
      th {
        font-weight: normal;
      }

      td {
        font-weight: bold;
      }
    }
  }

  .mat-mdc-dialog-actions {
    box-sizing: border-box;
  }

  .mat-mdc-row {
    height: 1.5em;
  }

  @media print {
    .print-hide {
      visibility: collapse !important;
      display: none !important;
    }

    .print-show {
      visibility: visible !important;
      height: auto;
    }
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  header {
    background: $header-background;
    border-bottom: 2px solid $primary-color;
    height: 50px;
    padding: 5px;

    .logo {
      max-height: 40px;
      //margin-top: 6px;
      margin-left: 5px;
    }

    .client-name-box,
    .version-box {
      background: $neutral;
      color: $header-background;
      padding: 8px;
      font-size: 14px;
      position: fixed;
      display: inline-block;
      z-index: 9;

      a {
        color: $header-background;
      }
    }

    .client-name-box {
      left: 50vw;
      transform: translateX(-50%);
      top: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .version-box {
      left: 0px;
      bottom: 0px;
      border-top-right-radius: 10px;
      z-index: 9999;
      font-weight: bold;
      background: $purple;
    }



    .pinned-notification {
      color: $header-background;
      font-size: 14px;
      margin-right: 120px;
      padding-right: 20px;
      display: inline-block;
      max-width: 50%;
      white-space: nowrap;
      vertical-align: top;
      transition: 0.2s linear;
      margin-top: 3px;

      &:hover {
        transform: scale(1.05);

        .message span {
          text-decoration: underline;
        }
      }

      .icon-circle {
        border-radius: 100%;
        background: $neutral;
        width: 40px;
        height: 40px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        transform: translateY(1px);

        .mat-icon {
          vertical-align: middle;
          font-size: 30px;
          transform: translateX(-3px) translateY(5px);
        }
      }

      .message {
        background: transparent;
        color: white;
        padding: 5px;
        vertical-align: middle;
        //  margin-left: -20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 20px;
        max-width: 100%;
        display: inline-block;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
          height: 24px;
          margin-left: -5px;
          margin-right: 2px;
        }

        .mat-icon {
          margin-right: 2px;
          margin-left: -5px;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }
      }
    }

    .user-info {
      color: white;
      display: inline-block;
      font-size: 12px;
      vertical-align: top;
      margin-right: 10px;

      a {
        color: white;
      }
    }

    .menu-button {
      color: $header-color;
      font-size: 46px;
      margin-right: 20px;
      margin-top: -4px;
      height: 46px;
      width: 46px;
    }

    ul.top-menu {
      list-style-type: none;
      margin: 0px;

      li {
        float: left;
        padding: 10px;

        a {
          color: $primary-color;
          margin-right: 10px;

          &:hover {
            color: white;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  table.styled {
    >tr {
      &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.05);
      }

      >td,
      >th {
        padding: 10px;

        &.unpadded {
          padding: 0px;
        }

        &.indented {
          padding-left: 20px;
        }
      }

      &.head-row {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .inactive {
      opacity: 0.6;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  button.styled,
  button.styled.mdc-button {
    padding: 10px;
    background: $neutral;
    color: white;
    outline: 0px;
    border: 0px;
    box-sizing: border-box;
    height: auto;
    line-height: 2.5em;


    &.mat-mdc-button.mat-mdc-button-base,
    &.mat-mdc-raised-button.mat-mdc-button-base,
    &.mat-mdc-unelevated-button.mat-mdc-button-base,
    &.mat-mdc-outlined-button.mat-mdc-button-base &:active {
      height: auto;
    }

    &:focus {
      outline: 0px;
    }

    &:disabled {
      opacity: 0.6;
      color: white;
    }

    &.neutral {
      background: $neutral;
      border-color: $neutral;

      &.outline {
        color: $neutral;
      }
    }

    &.green {
      background: $green;
      border-color: $green;

      &.outline {
        color: $green;
      }
    }

    &.red {
      background: $red;
      border-color: $red;

      &.outline {
        color: $red;
      }
    }

    &.outline {
      background: transparent;
      border-style: solid;
      border-width: 2px;
    }

    &.big {
      font-size: 18px;
    }
  }

  .green-text {
    color: $green;
  }

  .red-text {
    color: $red;
  }

  .yellow-text {
    color: $yellow;
  }

  .line-through {
    text-decoration: line-through;
  }

  table.mat-mdc-table {
    background: rgba(255, 255, 255, 0.02);

    &.fixed {
      table-layout: fixed;
    }

    tr {
      &.disabled {
        opacity: 0.2;
      }

      &.selectable:hover {
        background-color: rgb(225,225,225);
      }

      td,
      th {
        color: $primary-color;
      }

      td {
        //vertical-align: top;
      }
    }
  }
}

.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.valign-middle {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.valign-sub {
  vertical-align: sub;
}

.inline-block {
  display: inline-block;
}

.padded {
  padding: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-list .mat-tab-label {
  min-width: 50px;
  padding: 0px 10px;
}

.color-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 5px;
}

.mat-icon {
  &.big {
    height: 40px;
    width: 40px;
    font-size: 40px;
  }
}

.mat-mdc-card {
    &.primary-background {
        background: $background-color !important;
    }
}

.primary-background {
  background: $background-color;
}

hr {
  border-color: $primary-color;

  &.light {
    opacity: 0.1;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-body .mat-tab-body-content {
  overflow-x: hidden;
}

.main {
  margin-top: 20px;
  background: $wrapper-background-color;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);

  &.main {
    width: 90vw;
  }
}

.clickable {
  cursor: pointer;
}

input {
  color: $primary-color;

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $primary-color;
  }
}

.color-icon {
  font-size: 40px;
  transform: translateY(8px);
}

table.log {
  tr.info {

    th,
    td {
      color: $green;
    }
  }

  tr.warning {

    th,
    td {
      color: $yellow;
    }
  }

  tr.error {

    th,
    td {
      color: $red;
    }
  }
}

dl-date-time-picker {

  .dl-abdtp-date-button,
  .dl-abdtp-up-button {
    color: $primary-color !important;
  }

  .dl-abdtp-selected {
    background: $primary-color !important;
    color: $background-color !important;
  }
}

.selected-date {
  background: $primary-color;
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  display: inline-block;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
  overflow-x: hidden;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group .mat-tab-label-active {
  opacity: 1;
}

.tooltip-icon {
  color: $primary-color;
  margin: 5px;
  vertical-align: super;
  font-size: 25px;
  opacity: 0.3;
  transition: 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  //border-bottom-width: 0px;
}

.mat-mdc-tooltip.tooltip-content {
  color: $primary-color;
  font-size: 1.1em;
}

.mat-tooltip-linebreak {
  white-space: pre-wrap;
}

.mat-mdc-form-field.slim {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    padding-top: 0px;
    border-top: 0px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $primary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-select-value,
.mat-select-arrow,
.mat-tab-label,
.mat-tab-link {
  color: $primary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-frame {
  border-color: $primary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $button-background;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: $button-background;
  color: $button-color;
}

.bottom-margin-button,
.mat-mdc-raised-button.bottom-margin-button {
  vertical-align: bottom;
  margin-bottom: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-checkmark-path {
    stroke: $button-color !important;
  }

  &.large {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
      height: 25px;
      width: 25px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.transparent {
  background: transparent;
  color: $primary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.transparent {
  background: transparent;
  color: $primary-color;
}

dl-date-time-picker {

  .dl-abdtp-date-button[_ngcontent-c6],
  .dl-abdtp-left-button[_ngcontent-c6],
  .dl-abdtp-right-button[_ngcontent-c6],
  .dl-abdtp-view-label[_ngcontent-c6] {
    color: $primary-color;
    text-align: center;
  }

  button {
    background-color: transparent;
  }

  .dl-abdtp-selected[_ngcontent-c6] {
    background-color: $primary-color;
    color: black;
  }
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.users {
  tr.manager {

    th,
    td {
      color: $green;
    }
  }

  tr.user {

    th,
    td {
      color: $yellow;
    }
  }

  tr.disabled {
    opacity: 0.2;
  }
}

img.illustration {
  max-width: 100%;
  width: 100px;

  &.small {
    width: 50px;
  }

  &.large {
    width: 200px;
  }

  &.extra-large {
    width: 400px;
  }
}

.associated-resources,
.extra-activities,
.sortaable-table {
  tbody {
    tr:first-child {
      .up-arrow {
        opacity: 0.2;
        cursor: default;
      }
    }

    tr:last-child {
      .down-arrow {
        opacity: 0.2;
        cursor: default;
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-content .mat-icon {
  vertical-align: bottom;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix>label,
label.styled {
  opacity: 0.5;
}

.mat-mdc-form-field {
  &.time-input {
    width: 7em;
  }
}

mat-sidenav-container {
  min-height: 100vh;

  .mat-drawer-inner-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Chrome */
    }
  }

  mat-sidenav-content.mat-drawer-content {
    min-height: 100vh;
    overflow-x: hidden;
  }

  mat-sidenav.mat-drawer {
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.74));

    .side-menu {
      padding: 10px;
      margin-top: 50px;

      ul {
        list-style-type: none;
        padding-left: 20px;
        margin-bottom: 15px;

        li {
          font-size: 14px;
          // font-weight: bold;
          border-bottom: 2px solid #00000021;
          padding-top: 3px;
          padding-bottom: 3px;
          overflow: visible;
          position: relative;

          .mat-icon {
            color: $primary-color;
            margin-right: 8px;
            margin-left: 8px;
            transform: translateY(6px);
          }

          i {
            color: $primary-color;
            margin-right: 8px;
            margin-left: 8px;

            &.fa,
            &.fas {
              margin-top: 6px;
              margin-bottom: 6px;
              margin-right: 15px;
            }
          }

          a {
            color: $primary-color;

            &:focus,
            &:active {
              outline: none;
              border: none;
            }
          }

          .beta-icon {
            background: $neutral-color;
            border-radius: 100%;
            padding: 3px;
            position: absolute;
            top: 2px;
            left: -5px;
            color: white;
            font-size: 9px;
            font-weight: bold;
          }

          ul li {
            ul.submenu {
              margin-left: 10px;
              margin-bottom: 0px;
              opacity: 0;
              max-height: 0px;
              transition: 0.4s ease-in;

              li:last-child {
                border-bottom: 0px;
              }

              &.expanded {
                opacity: 1;
                max-height: 1000px;
              }
            }
          }
        }
      }

      >ul {
        padding-left: 0px;

        >li {
          border: 0px;
        }
      }
    }
  }
}

input.mat-mdc-input-element {
  line-height: 1em;
}

.article-number-chooser-fix {
  margin-top: 16px;
  display: block;
}

pre.code {
  width: 100%;
  white-space: pre-wrap;
}

.campaign-label-preview {
  margin: 5px;
  padding: 5px 15px;
  display: table;
  border-radius: 5px;
}

.dialog-close-icon {
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
    background: white;
    z-index: 99999999999;
    border-radius: 5em;

    &:hover {
        cursor: pointer;
        border-radius: 1000em;
        background-color: $base-color-2;
    }
}
